import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { AuthContext } from "../../context/auth.context.js";
import { toast } from "react-toastify";
import carlImage from "../../assets/Carl_Case.png";
import { API_URL, API_URL_NEW_BACKEND } from "../../config/config.index.js";
import SaveModal from "./SaveModal.jsx";
import LatestFeedback from "./LatestFeedBackComponent.jsx";
import Tips from "./Tips.jsx";
import ProgressBar from "../ProgressBar.jsx";
import CaseCreationForm from "./CaseCreationForm.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faStop, faSave, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import SpeechIndicator from "./SpeechIndicator.jsx";
import "../../css/CombinedSection.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CarlCase = () => {
  const [inputValue, setInputValue] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCaseStarted, setIsCaseStarted] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [currentStage, setCurrentStage] = useState("Case Introduction");
  const [currentSubStage, setCurrentSubStage] = useState("background");
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [latestFeedback, setLatestFeedback] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const { user } = useContext(AuthContext);
  const { isLoggedIn } = useContext(AuthContext);
  const eventSourceRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatAreaRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    scrollToBottom();
  }, [conversationHistory]);

  useEffect(() => {
    const fetchLatestFeedback = async () => {
      if (user && user._id) {
        try {
          const response = await fetch(`${API_URL}/analysis/latest-feedback/${user._id}`);
          const data = await response.json();
          setLatestFeedback(data);
        } catch (error) {
          console.error("Error fetching latest feedback:", error);
        }
      }
    };

    fetchLatestFeedback();
  }, [user]);

  const scrollToBottom = (smooth = true) => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  };

  const addToConversation = useCallback((type, text) => {
    setConversationHistory((prev) => {
      const newHistory =
        type === "api" && prev.length > 0 && prev[prev.length - 1].type === "api"
          ? [...prev.slice(0, -1), { ...prev[prev.length - 1], text }]
          : [...prev, { type, text: text || "" }];

      setTimeout(() => {
        scrollToBottom();
        setTimeout(scrollToBottom, 100);
      }, 0);

      return newHistory;
    });
  }, []);

  const handleSend = async () => {
    const trimmedInput = inputValue.trim();
    if (trimmedInput === "" || loading) return;

    setLoading(true);
    addToConversation("user", trimmedInput);
    setInputValue("");
    setIsCaseStarted(true);

    try {
      await fetchOpenAIResponseStreaming(trimmedInput, threadId);
    } catch (error) {
      console.error("Error fetching OpenAI response:", error);
      addToConversation("api", "Sorry, I encountered an error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOpenAIResponseStreaming = async (promptText, currentThreadId) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    try {
      const response = await fetch(`${API_URL_NEW_BACKEND}/api/assistant-chat`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userMessage: promptText,
          threadId: currentThreadId,
          stage: currentStage,
          subStage: currentSubStage,
        }),
      });

      const data = await response.json();
      const newThreadId = data.threadId;

      if (!currentThreadId) {
        setThreadId(newThreadId);
      }

      if (newThreadId) {
        const newEventSource = new EventSource(`${API_URL_NEW_BACKEND}/api/stream-responses?threadId=${newThreadId}`);
        let rawContent = "";

        newEventSource.addEventListener("messageStart", () => {
          rawContent = "";
          addToConversation("api", "");
        });

        newEventSource.addEventListener("textDelta", (event) => {
          const eventData = JSON.parse(event.data);
          rawContent += eventData.value;
          const cleanedResponse = handleAIResponse(rawContent);
          const html = DOMPurify.sanitize(marked(cleanedResponse));
          addToConversation("api", html);
        });

        newEventSource.onopen = () => console.log("SSE connection opened successfully.");
        newEventSource.onerror = (event) => {
          console.error("SSE connection encountered an error:", event);
          setLoading(false);
          newEventSource.close();
        };

        eventSourceRef.current = newEventSource;
      } else {
        console.error("No threadId received from the server");
        addToConversation("api", "Sorry, I encountered an error. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      addToConversation("api", "Sorry, I encountered an error. Please try again.");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleNewCase = () => {
    setIsCaseStarted(false);
    setConversationHistory([]);
    setInputValue("");
    setThreadId(null);
    setProgress(0);
    setCurrentStage("Case Introduction");
    setCurrentSubStage("background");
    setShowForm(true);
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  };

  const stages = {
    "Case Introduction": { weight: 0.1, subStages: ["background", "problem_definition"] },
    "Candidate Response Phase": { weight: 0.15, subStages: ["initial_strategy", "clarification"] },
    "Deep Dive Analysis": { weight: 0.5, subStages: ["data_analysis", "hypothesis_testing", "framework_application"] },
    "Conclusion and Recommendation": { weight: 0.15, subStages: ["synthesis", "recommendation"] },
    "Feedback and Skills": { weight: 0.1, subStages: ["strengths", "areas_for_improvement"] },
  };

  const calculateProgress = (progressInfo) => {
    const { stage, subStage, completion } = progressInfo;
    let totalProgress = 0;

    for (const [currentStage, data] of Object.entries(stages)) {
      if (currentStage === stage) {
        const subStageIndex = data.subStages.indexOf(subStage);
        const subStageProgress = (subStageIndex + completion) / data.subStages.length;
        totalProgress += data.weight * subStageProgress;
        break;
      }
      totalProgress += data.weight;
    }

    return Math.min(Math.round(totalProgress * 100), 100);
  };

  const handleAIResponse = (response) => {
    const progressRegex = /\[PROGRESS:({[^}]+})\]/;
    const match = response.match(progressRegex);

    if (match) {
      const progressInfo = JSON.parse(match[1]);
      const newProgress = calculateProgress(progressInfo);
      setProgress(newProgress);
      setCurrentStage(progressInfo.stage);
      setCurrentSubStage(progressInfo.subStage);

      // Remove the progress information from the displayed response
      const cleanedResponse = response.replace(progressRegex, "");
      return cleanedResponse;
    }

    return response;
  };

  const handleSaveClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (!isSaving) {
      setIsModalOpen(false);
    }
  };

  const handleSaveAndEndCase = () => {
    if (!isLoggedIn) {
      toast.info("You have to be logged in to save your case.");
      return;
    }

    setIsSaving(true);

    // Start the save process
    saveAndAnalyzeConversation();

    // Show spinner for exactly 2 seconds
    setTimeout(() => {
      setIsSaving(false);
      setIsModalOpen(false);
      setIsCaseStarted(false);
    }, 4000);
  };

  const saveAndAnalyzeConversation = () => {
    const token = localStorage.getItem("authToken");
    axios
      .post(
        `${API_URL_NEW_BACKEND}/analysis/analyze`,
        {
          conversationHistory: conversationHistory.map((msg) => ({
            type: msg.type || (msg.role === "user" ? "user" : "assistant"),
            text: msg.text || msg.content,
          })),
          userId: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        console.log("Analysis process completed successfully");
        toast.success("Great job! 🎉 Your case has been saved and analyzed. Your results are ready to view on the Progress page.", {
          autoClose: 4000, // Keep the toast visible for 4 seconds
        });
        setTimeout(() => {
          navigate("/progress");
        }, 4500); // Navigate shortly after the toast message
      })
      .catch((error) => {
        console.error("Error initiating analysis process:", error);
        toast.error("There was an issue saving the case and initiating the analysis. Please try again.");
      });
  };

  const handleFormSubmit = async (formData) => {
    setShowForm(false);
    const promptText = `Create a case with the following details: Case Type - ${formData.caseType}, Industry - ${formData.industry}`;
    setCurrentStage("Case Introduction");
    setCurrentSubStage("background");
    setProgress(0);
    setThreadId(null);
    setConversationHistory([]);
    setIsCaseStarted(true);
    await fetchOpenAIResponseStreaming(promptText, null);
  };

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.warn("getUserMedia not supported on your browser!");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();
        setIsRecording(true);

        recorder.ondataavailable = async (e) => {
          const audioData = new Blob([e.data], { type: "audio/wav" });
          const formData = new FormData();
          formData.append("file", audioData, "audio.wav");

          try {
            const response = await fetch(`${API_URL_NEW_BACKEND}/api/transcribe`, {
              method: "POST",
              body: formData,
            });
            const data = await response.json();
            setInputValue(data.transcription.text);
          } catch (error) {
            console.error("Error while sending audio data:", error);
          }
        };

        recorder.onerror = (err) => {
          console.error("Recorder error:", err);
          setIsRecording(false);
        };

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop());
          setIsRecording(false);
        };
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  return (
    <div className="carl-case-container">
      {!isCaseStarted ? (
        <>
          <div className="carl-case-main">
            <div className="carl-case-feedback">
              <LatestFeedback keyTakeaways={latestFeedback ? latestFeedback.keyTakeaways : []} />
            </div>
            <div className="carl-case-creation">
              <CaseCreationForm onSubmit={handleFormSubmit} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: "100%", maxWidth: "800px", margin: "20px auto", padding: "10px 0" }}>
            <ProgressBar targetProgress={progress} />
          </div>
          <div className="tips-wrapper" style={{ position: "relative" }}>
            <Tips />
          </div>
          <div className="combined-interactive-area">
            <div className="combined-display-area full-width">
              <div className="chatbot-container chat-active">
                <div className="chat-area" ref={chatAreaRef}>
                  {conversationHistory.map((message, index) => (
                    <div key={index} className={`message-wrapper ${message.type}`}>
                      {message.type === "user" ? <div className="user-icon">👤</div> : <img src={carlImage} alt="Carl" className="ai-icon" />}
                      <div className={`message ${message.type === "user" ? "user-message" : "ai-message"}`}>
                        <p dangerouslySetInnerHTML={{ __html: message.text }} />
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="chat-footer-container">
                  <div className="chat-footer">
                    <div className="chat-input-wrapper">
                      <button type="button" className="add-button" title="Add attachment">
                        +
                      </button>
                      <div className="input-with-icons">
                        <input
                          type="text"
                          className="chat-input"
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                          placeholder="Send a message."
                          disabled={loading}
                        />
                        <button
                          type="button"
                          className={`record-button ${isRecording ? "recording" : ""}`}
                          onClick={isRecording ? stopRecording : startRecording}
                        >
                          <FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />
                        </button>
                      </div>
                      <button
                        type="submit"
                        className="send-button"
                        onClick={handleSend}
                        disabled={loading || isRecording || inputValue.trim() === ""}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                    </div>
                    <button className="new-case-button" onClick={handleNewCase}>
                      New Case
                    </button>
                    {/*TODO: Uncomment after fixing stripe*/}
                    {progress >= 0 && (
                      <button type="button" className="save-button-carl" onClick={handleSaveClick} disabled={isSaving}>
                        <FontAwesomeIcon icon={faSave} /> {isSaving ? "Saving..." : "Save and Analyze Case"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <SpeechIndicator isRecording={isRecording} onStop={stopRecording} />
      <SaveModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveAndEndCase} isSaving={isSaving} progress={progress} />
    </div>
  );
};

export default CarlCase;

{
  /* <Helmet>
  <title>Interactive AI Case Practice with Carl - Tailor Your Consulting Case Prep | Case Study Generator</title>
  <meta
    name="description"
    content="Engage with Carl, our AI-driven mentor, for a dynamic and personalized case study practice. Create custom case interviews by industry, case type, and skills such as market sizing, with instant feedback to enhance your consulting preparation."
  />
  <link rel="canonical" href="https://www.casestudygenerator.com/practice-case-interview" />
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Interactive AI Case Mentor - Carl",
        "description": "Use Carl to simulate consulting case interviews tailored to your choice of industry and skills. Get instant feedback and master case interview techniques.",
        "url": "https://www.casestudygenerator.com/practice-case-interview"
      }
    `}
  </script>
  </Helmet> */
}
